<template>
  <transition name="fade" appear>
    <div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>平台介绍</el-breadcrumb-item>
      </el-breadcrumb>

      <div style="margin-top:30px" v-html="content" class="ql-editor text-left"></div>
    </div>
  </transition>
</template>

<script>
export default {
  data () {
    return {
      content: '',
    }
  },
  methods: {

    queryPlatformIntroduce () {
      var param = {
      }
      this.$api.user.queryPlatformIntroduce(param).then(res => {
        if (res.data.code === 200) {
          this.content = res.data.data.platform_introduce
        }
      })
    },


  },

  created () {
    this.queryPlatformIntroduce()

  },
}

</script>

<style>
</style>